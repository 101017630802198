
import { defineComponent, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import {
  useSupports,
  useDeleteSupport,
  useSolveSupport,
  useArchiveSupport
} from '@/composables/api';
import { SupportIdOptions } from '@/services/api';

import { SupportStatus } from '@/interfaces/Support';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: 'Status',
    placeholder: 'Please select an option',
    options: [
      {
        label: 'Opening',
        value: SupportStatus.OPENING
      },
      {
        label: 'Solved',
        value: SupportStatus.SOLVED

      }
    ]
  },
  {
    type: FilterType.CHECKBOX,
    label: 'Is Archived'
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref('');
    const status = ref();
    const archived = ref();
    const { data, isLoading, isFetching, refetch } = useSupports({ page, keyword, status, archived });
    const { isLoading: isDeletedLoading, mutate } = useDeleteSupport();
    const { isLoading: isSolvedLoading, mutate: solveMutate } =
      useSolveSupport();
    const { isLoading: isArchivedLoading, mutate: archiveMutate } =
      useArchiveSupport();
    const statusIndex = ref(undefined);

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };
    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      status.value = event[0];
      archived.value = event[1] ? '1' : '0';
    };

    const deleteSupport = ({ supportId }: SupportIdOptions, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { supportId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const solveSupport = ({ supportId }: SupportIdOptions, index: Number) => {
      statusIndex.value = index;
      solveMutate(
        { supportId },
        {
          onSuccess() {
            refetch.value();

            ElMessage({
              type: 'success',
              message: 'Enable successfully'
            });
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    const archiveSupport = ({ supportId }: SupportIdOptions, index: Number) => {
      statusIndex.value = index;
      archiveMutate(
        { supportId },
        {
          onSuccess() {
            refetch.value();

            ElMessage({
              type: 'success',
              message: 'Enable successfully'
            });
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    return {
      page,
      data,
      SupportStatus,
      isLoading,
      isFetching,
      isDeletedLoading,
      deleteSupport,
      isSolvedLoading,
      solveSupport,
      isArchivedLoading,
      archiveSupport,
      statusIndex,
      searchKeyword,
      handleFilterChange,
      FILTER_OPTIONS
    };
  }
});
